/**
 * Cohelion Demo Group
 * API documentation for Cohelion Demo Group
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';

import { ActionButtonResult }                                     from '../model/actionButtonResult';
import { ApprovalDetailResponse }                                     from '../model/approvalDetailResponse';
import { ApprovalOverviewResponse }                                     from '../model/approvalOverviewResponse';
import { CommentData }                                     from '../model/commentData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DataentryroundsService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Trigger action button , returns ActionButtonResult
     * Trigger action button , returns ActionButtonResult
     * @param scopename Scope name
     * @param buttonName Button name
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string, must contain IdDataEntryRound and IdOffice
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentryroundsApprovaldetailScopenameButtonButtonNamePost(scopename: string, buttonName: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ActionButtonResult>;
    public dataentryroundsApprovaldetailScopenameButtonButtonNamePost(scopename: string, buttonName: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActionButtonResult>>;
    public dataentryroundsApprovaldetailScopenameButtonButtonNamePost(scopename: string, buttonName: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActionButtonResult>>;
    public dataentryroundsApprovaldetailScopenameButtonButtonNamePost(scopename: string, buttonName: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scopename === null || scopename === undefined) {
            throw new Error('Required parameter scopename was null or undefined when calling dataentryroundsApprovaldetailScopenameButtonButtonNamePost.');
        }
        if (buttonName === null || buttonName === undefined) {
            throw new Error('Required parameter buttonName was null or undefined when calling dataentryroundsApprovaldetailScopenameButtonButtonNamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ActionButtonResult>(`${this.basePath}/dataentryrounds/approvaldetail/${encodeURIComponent(String(scopename))}/button/${encodeURIComponent(String(buttonName))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Save comment
     * Save comment
     * @param scopename Scope name
     * @param idfacttable IdFactTable or IdFactTable_SectionKey
     * @param message Comment content
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string, must contain IdDataEntryRound and IdOffice
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentryroundsApprovaldetailScopenameCommentPost(scopename: string, idfacttable: string, message: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<CommentData>;
    public dataentryroundsApprovaldetailScopenameCommentPost(scopename: string, idfacttable: string, message: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommentData>>;
    public dataentryroundsApprovaldetailScopenameCommentPost(scopename: string, idfacttable: string, message: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommentData>>;
    public dataentryroundsApprovaldetailScopenameCommentPost(scopename: string, idfacttable: string, message: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scopename === null || scopename === undefined) {
            throw new Error('Required parameter scopename was null or undefined when calling dataentryroundsApprovaldetailScopenameCommentPost.');
        }
        if (idfacttable === null || idfacttable === undefined) {
            throw new Error('Required parameter idfacttable was null or undefined when calling dataentryroundsApprovaldetailScopenameCommentPost.');
        }
        if (message === null || message === undefined) {
            throw new Error('Required parameter message was null or undefined when calling dataentryroundsApprovaldetailScopenameCommentPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idfacttable !== undefined) {
            formParams = formParams.append('idfacttable', <any>idfacttable) || formParams;
        }
        if (message !== undefined) {
            formParams = formParams.append('message', <any>message) || formParams;
        }
        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<CommentData>(`${this.basePath}/dataentryrounds/approvaldetail/${encodeURIComponent(String(scopename))}/comment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get detail data for the DataEntryRound
     * @param scopename Scope name
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string, must contain IdDataEntryRound and IdOffice
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentryroundsApprovaldetailScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ApprovalDetailResponse>;
    public dataentryroundsApprovaldetailScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalDetailResponse>>;
    public dataentryroundsApprovaldetailScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalDetailResponse>>;
    public dataentryroundsApprovaldetailScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scopename === null || scopename === undefined) {
            throw new Error('Required parameter scopename was null or undefined when calling dataentryroundsApprovaldetailScopenamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ApprovalDetailResponse>(`${this.basePath}/dataentryrounds/approvaldetail/${encodeURIComponent(String(scopename))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get overview data for the DataEntryRound
     * @param scopename Scope name
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentryroundsApprovaloverviewScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ApprovalOverviewResponse>;
    public dataentryroundsApprovaloverviewScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalOverviewResponse>>;
    public dataentryroundsApprovaloverviewScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalOverviewResponse>>;
    public dataentryroundsApprovaloverviewScopenamePost(scopename: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scopename === null || scopename === undefined) {
            throw new Error('Required parameter scopename was null or undefined when calling dataentryroundsApprovaloverviewScopenamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ApprovalOverviewResponse>(`${this.basePath}/dataentryrounds/approvaloverview/${encodeURIComponent(String(scopename))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
