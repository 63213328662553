/**
 * Cohelion Demo Group
 * API documentation for Cohelion Demo Group
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';

import { DownloadsCategory }                                     from '../model/downloadsCategory';
import { DownloadsItem }                                     from '../model/downloadsItem';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DownloadsService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Get list of all download items in a category
     * @param categoryId Downloads category ID. NOTE: Not implemented yet so any value is fine.
     * @param maxRecords Number of download items to be returned
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadsCategoriesCategoryIdItemsGet(categoryId: number, maxRecords?: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Array<DownloadsItem>>;
    public downloadsCategoriesCategoryIdItemsGet(categoryId: number, maxRecords?: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DownloadsItem>>>;
    public downloadsCategoriesCategoryIdItemsGet(categoryId: number, maxRecords?: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DownloadsItem>>>;
    public downloadsCategoriesCategoryIdItemsGet(categoryId: number, maxRecords?: number, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling downloadsCategoriesCategoryIdItemsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (maxRecords !== undefined) {
            queryParameters = queryParameters.set('maxRecords', <any>maxRecords);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<Array<DownloadsItem>>(`${this.basePath}/downloads/categories/${encodeURIComponent(String(categoryId))}/items`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Downloads the file attached to requested download item ID
     * @param categoryId Downloads category ID. NOTE: Not implemented yet so any value is fine.
     * @param itemId Downloadable item ID
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadsCategoriesCategoryIdItemsItemIdGet(categoryId: number, itemId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadsCategoriesCategoryIdItemsItemIdGet(categoryId: number, itemId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadsCategoriesCategoryIdItemsItemIdGet(categoryId: number, itemId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadsCategoriesCategoryIdItemsItemIdGet(categoryId: number, itemId: number, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling downloadsCategoriesCategoryIdItemsItemIdGet.');
        }
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling downloadsCategoriesCategoryIdItemsItemIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get(`${this.basePath}/downloads/categories/${encodeURIComponent(String(categoryId))}/items/${encodeURIComponent(String(itemId))}`,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get list of all download item categories. NOTE: Not real values from database
     * Get list of all download item categories. NOTE: Not real values from database
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadsCategoriesGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Array<DownloadsCategory>>;
    public downloadsCategoriesGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DownloadsCategory>>>;
    public downloadsCategoriesGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DownloadsCategory>>>;
    public downloadsCategoriesGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<Array<DownloadsCategory>>(`${this.basePath}/downloads/categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get list of all recently added download items
     * Get list of all recently added download items
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadsRecentGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Array<DownloadsItem>>;
    public downloadsRecentGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DownloadsItem>>>;
    public downloadsRecentGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DownloadsItem>>>;
    public downloadsRecentGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<Array<DownloadsItem>>(`${this.basePath}/downloads/recent`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
